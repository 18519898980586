import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/material";
import { useIntl } from "react-intl";

interface OfferCardProps {
  image: string;
  target: string;
  title: string;
}

const OfferCard = ({ image, target, title }: OfferCardProps) => {
  const theme = useTheme();

  return (
    <div
      className="homepage_project_container_types_row1"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        height: "100%",
        background: theme.palette.background.paper,
        borderRadius: "12px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        width: "350px",
      }}
    >
      {/* Image */}
      <div
        style={{
          width: "100%",
        }}
      >
        <img
          src={image}
          alt={title}
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
            borderRadius: "10px 10px 0 0",
          }}
        />
      </div>

      {/* Title */}
      <p
        style={{
          fontSize: "20px",
          color: "black",
          padding: "0 16px",
        }}
      >
        {title}
      </p>
    </div>
  );
};

// Modify the articles array to include language-specific links
const articles = [
  {
    id: "article1",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/1.png",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/2.png",
    },
    title: "Paving the way to CO₂ neutrality – every contribution counts",
    links: {
      en: "https://blog.freezecarbon.com/i-paving-the-way-to-net-zero-making-every-climate-contribution-count/",
      de: "https://blog.freezecarbon.com/i-der-weg-zur-co₂-neutralitaet-ebnen-jeder-beitrag-zaehlt/",
    },
  },
  {
    id: "article2",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/19.png",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/20.png",
    },
    title:
      "Avoid or remove? Choosing the right climate contributions for a meaningful impact",
    links: {
      en: "https://blog.freezecarbon.com/ii-avoid-or-remove-choosing-the-right-climate-contributions-for-a-meaningful-impact/",
      de: "https://blog.freezecarbon.com/ii-vermeiden-oder-entfernen-die-richtigen-klimaschutzprojekte-fuer-eine-wirkungsvolle-veraenderung-waehlen/",
    },
  },
  {
    id: "article3",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/21.png",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/22.png",
    },
    title:
      "Choosing wisely: What are key criteria for high-impact climate contributions?",
    links: {
      en: "https://blog.freezecarbon.com/iii-choosing-wisely-what-are-key-criteria-for-high-impact-climate-contributions/",
      de: "https://blog.freezecarbon.com/iii-kluge-entscheidungen-treffen-die-wichtigsten-kriterien-fuer-wirkungsvolle-klimaschutzbeitraege/",
    },
  },
  {
    id: "article4",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/30.png",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/31.png",
    },
    title:
      "The economics of carbon credits: Price dynamics driven by supply and demand",
    links: {
      en: "https://blog.freezecarbon.com/iv-the-economics-of-carbon-credits-price-dynamics-driven-by-supply-and-demand/",
      de: "https://blog.freezecarbon.com/iv-oekonomie-von-klimaschutzprojekten-die-preisdynamik-durch-angebot-und-nachfrage/",
    },
  },
  {
    id: "article5",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/27.png",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/27.png",
    },
    title: "Ensuring impact: The role of certification in carbon credits",
    links: {
      en: "https://blog.freezecarbon.com/v-ensuring-impact-the-role-of-certification-in-carbon-credits/",
      de: "https://blog.freezecarbon.com/v-wirkung-sicherstellen-die-rolle-der-zertifizierung-von-klimaschutzprojekten/",
    },
  },
  {
    id: "article6",
    image: {
      en: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/50.webp",
      de: "https://storage.googleapis.com/freezecarbon-dev-public/learn_academy/51.webp",
    },
    title:
      "SDGs and climate protection: Building a sustainable future together",
    links: {
      en: "https://blog.freezecarbon.com/vi-sdgs-and-climate-protection-building-a-sustainable-future-together/",
      de: "https://blog.freezecarbon.com/vi-sdgs-und-klimaschutz-gemeinsam-eine-nachhaltige-zukunft-gestalten/",
    },
  },
];

const FeatureSection = () => {
  const intl = useIntl();
  const language = intl.locale.split("-")[0]; // Extract the language code

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          flexWrap: "wrap",
        }}
      >
        {articles.map((article, index) => {
          console.log("Current language:", language);

          // Get the language-specific link for the article
          const targetLink = article.links[language] || article.links.en; // Fallback to English if no language match
          const targetImage = article.image[language] || article.image.en; // Fallback to English if no language match
          console.log("Target image:", targetImage);

          return (
            <a
              key={index}
              href={targetLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <OfferCard
                image={targetImage}
                target={targetLink}
                title={intl.formatMessage({
                  id: article.id,
                  defaultMessage: article.title,
                })}
              />
            </a>
          );
        })}
      </div>
    </Container>
  );
};

export default FeatureSection;
